/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import InlineLoading from 'vux/src/components/inline-loading';
import Bubble from '../Bubble/Bubble';
import BScroll from 'better-scroll';
import Empty from '../Empty/Empty';
export default {
  name: 'Scroll',
  components: {
    Bubble: Bubble,
    InlineLoading: InlineLoading,
    Empty: Empty
  },
  props: {
    emptyShow: {
      type: Boolean,
      default: false
    },
    emptyTip: {
      type: String,
      default: "暂无记录"
    },
    scrollbar: {
      // 是否显示滚动条
      type: Boolean,
      default: false
    },
    pullDownRefresh: {
      // 是否开启下拉刷新
      type: null,
      default: false
    },
    pullUpLoad: {
      // 是否开启上拉加载
      type: null,
      default: false
    },
    probeType: {
      // 滚动事件类型
      type: Number,
      default: 1
    },
    listenScroll: {
      // 是否监听滚动事件
      type: Boolean,
      default: false
    },
    pullupPaddingBottom: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      defaultLoadTxtMore: '加载中',
      defaultLoadTxtNoMore: '-- -- 我是有底线的 -- --',
      defaultRefreshTxt: '刷新成功',
      isPullUpLoad: false,
      // 正在上拉加载
      isPullingDown: false,
      // 下拉松手后
      isRebounding: false,
      // 回弹
      beforePullDown: true,
      // 下拉松手前
      finshPullupLoad: false,
      // 结束上拉加载
      pullUpDirty: true,
      // 上拉加载是否还有更多
      pullDownStyle: '',
      // 下拉刷新的位置
      bubbleY: 0 // 气泡偏移量

    };
  },
  computed: {
    // 上拉加载提示信息
    pullUpTip: function pullUpTip() {
      // 开启上拉加载 并且有 tip 属性 并且 tip 属性里还有 more 属性,否则默认值
      var moreTip = this.pullUpLoad && this.pullUpLoad.tip && this.pullUpLoad.tip.more || this.defaultLoadTxtMore; // 开启上拉加载 并且有 tip 属性 并且 tip 属性里还有 noMore 属性,否则默认值

      var noMoreTip = this.pullUpLoad && this.pullUpLoad.tip && this.pullUpLoad.tip.noMore || this.defaultLoadTxtNoMore;
      return this.pullUpDirty ? moreTip : noMoreTip;
    },
    // 下拉刷新提示信息
    refreshTip: function refreshTip() {
      return this.pullDownRefresh && this.pullDownRefresh.tip || this.defaultRefreshTxt;
    }
  },
  created: function created() {
    // 初始下拉显示位置
    this.pullDownInitTop = -150;
  },
  mounted: function mounted() {
    var _this = this;

    this.$nextTick(function () {
      _this.initScroll();
    });
  },
  methods: {
    // 初始化滚动
    initScroll: function initScroll() {
      var _this2 = this;

      if (!this.$refs.wrapper) {
        return;
      }

      if (this.pullupPaddingBottom) {
        this.$refs.listWrapper.style.paddingBottom = "".concat(this.pullupPaddingBottom, "px");
      }

      if (this.$refs.listWrapper && (this.pullDownRefresh || this.pullUpLoad)) {
        this.$refs.listWrapper.style.minHeight = "".concat(this.$refs.wrapper.offsetHeight + 9, "px");
      }

      this.scroll = new BScroll(this.$refs.wrapper, {
        click: true,
        // 开启点击事件,这个不做配置因为都会用到
        scrollbar: this.scrollbar,
        // 滚动条
        pullDownRefresh: this.pullDownRefresh,
        // 下拉刷新
        pullUpLoad: this.pullUpLoad,
        // 上拉加载
        probeType: this.probeType // 滚动事件类型

      }); // 开启监听滚动事件

      if (this.listenScroll) {
        this.scroll.on('scroll', function (pos) {
          _this2.$emit('scroll', pos);
        });
      } // 开启监听下拉刷新


      if (this.pullDownRefresh) {
        this._initPullDownRefresh();
      } // 开启上拉加载


      if (this.pullUpLoad) {
        this._initPullUpLoad();
      }
    },
    // 刷新滚动
    refresh: function refresh() {
      this.scroll && this.scroll.refresh();
    },
    // 关闭
    closePullUp: function closePullUp() {
      this.scroll && this.scroll.closePullUp();
    },
    // 更新数据
    forceUpdate: function forceUpdate(noMore) {
      var _this3 = this;

      // 开启了下拉刷新,并且在刷新中
      if (this.pullDownRefresh && this.isPullingDown) {
        this.isPullingDown = false;

        this._reboundPullDown().then(function () {
          // 显示下拉刷新 成果
          _this3._afterPullDown();
        });
      } else if (this.pullUpLoad && this.isPullUpLoad) {
        this.isPullUpLoad = false;
        this.scroll.finishPullUp();
        this.pullUpDirty = noMore;
        this.refresh();
      } else {
        this.refresh();
      }
    },
    // 初始下拉刷新
    _initPullDownRefresh: function _initPullDownRefresh() {
      var _this4 = this;

      this.scroll.on('pullingDown', function () {
        _this4.beforePullDown = false;
        _this4.isPullingDown = true; // 下拉刷新

        _this4.pullUpDirty = false; // 隐藏上拉加载提示

        _this4.$emit('pullingDown');
      });
      this.scroll.on('scroll', function (pos) {
        // 如果没有开启下拉刷新,就什么都不做
        if (!_this4.pullDownRefresh) {
          return;
        } // 如果还没松手


        if (_this4.beforePullDown) {
          _this4.bubbleY = Math.max(0, pos.y + _this4.pullDownInitTop);
          _this4.pullDownStyle = "top:".concat(Math.min(pos.y + _this4.pullDownInitTop, 10), "px");
        } else {
          _this4.bubbleY = 0;
        }

        if (_this4.isRebounding) {
          _this4.pullDownStyle = "top:".concat(10 - (_this4.pullDownRefresh.stop - pos.y), "px");
        }
      });
    },
    // 下拉刷新的回弹
    _reboundPullDown: function _reboundPullDown() {
      var _this5 = this;

      var _this$pullDownRefresh = this.pullDownRefresh.stopTime,
          stopTime = _this$pullDownRefresh === void 0 ? 200 : _this$pullDownRefresh;
      return new Promise(function (resolve) {
        setTimeout(function () {
          _this5.isRebounding = true;

          _this5.scroll.finishPullDown();

          resolve();
        }, stopTime);
      });
    },
    // 下拉回弹之后
    _afterPullDown: function _afterPullDown() {
      var _this6 = this;

      setTimeout(function () {
        _this6.pullDownStyle = "top:".concat(_this6.pullDownInitTop, "px");
        _this6.beforePullDown = true;
        _this6.isRebounding = false;

        _this6.refresh();
      }, this.scroll.options.bounceTime);
    },
    // 初始上拉加载
    _initPullUpLoad: function _initPullUpLoad() {
      var _this7 = this;

      this.scroll.on('pullingUp', function () {
        _this7.isPullUpLoad = true;

        _this7.$emit('pullingUp');
      });
    }
  },
  destroyed: function destroyed() {
    this.$refs.scroll && this.$refs.scroll.destroy();
  }
};